

import './App.css';

function Home() {
    return (
        <div className="App">
            <header className="App-header">

                <p>
                    Bulunamadı :(
                </p>

            </header>
        </div>
    );
}

export default Home;
